import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import { Helmet } from 'react-helmet';
import Logo from '../../components/logo/Logo';
import './LandingPage.scss';

export default function LandingPage() {
  const navigate = useNavigate();

  const onLoginClick = useCallback(() => {
    navigate('/giris-yap');
  }, [navigate]);

  const onCreateAccountClick = useCallback(() => {
    navigate('/uye-ol');
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Yatirimi - Yatırımlarınızı Akıllıca Yönetin</title>
        <meta name="description" content="Hisse senetleri, yatırım fonları, kripto paralar ve tüm finansal varlıklarınızı tek ekranda takip edin. Portföyünüzü analiz edin, performansınızı ölçün." />
      </Helmet>

      <main className="landing-page">
        <section className="content-block">
          <article className="dx-card responsive-paddings">
            <header>
              <Logo width={250} alt="Yatirimi Logo" />
              
              <h1 className="main-heading">
                Yatırımlarınızı Akıllıca Yönetin!
              </h1>
            </header>

            <div className="hero-text">
              <p>
                Hisse senetleri, yatırım fonları, kıymetli madenler, dövizler, kripto paralar ve diğer finansal varlıklarınızın getirisini
                detaylı raporlar ve grafiklerle analiz edin. Portföyünüzün enflasyon ve döviz
                karşısındaki durumunu öğrenin.
              </p>
            </div>

            <div className="action-buttons">
              <Button
                icon="user"
                width={280}
                text="Hemen ücretsiz üye ol!"
                type="default"
                stylingMode="contained"
                onClick={onCreateAccountClick}
                aria-label="Ücretsiz üye ol"
              />
              <Button
                icon="login"
                width={160}
                text="Giriş Yap"
                type="default"
                stylingMode="outlined"
                onClick={onLoginClick}
                aria-label="Giriş yap"
              />
            </div>

            <nav className="store-buttons" aria-label="Uygulama mağazaları">
              <a
                href="https://apps.apple.com/tr/app/yatirimi/id6480345591"
                rel="noreferrer"
                target="_blank"
                aria-label="App Store'dan indir"
              >
                <img
                  src="https://losmedia.com/logoappstore.png"
                  width={140}
                  alt="App Store'dan indir"
                  loading="lazy"
                />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.yatirimi"
                rel="noreferrer"
                target="_blank"
                aria-label="Google Play'den indir"
              >
                <img
                  src="https://losmedia.com/logoplaystore.png"
                  width={140}
                  alt="Google Play'den indir"
                  loading="lazy"
                />
              </a>
            </nav>
          </article>
        </section>

        <footer className="credit">
          <a 
            href="https://losmedia.com" 
            rel="noreferrer" 
            target="_blank"
            aria-label="Losmedia'yı ziyaret et"
          >
            <img
              src="https://losmedia.com/wp-content/uploads/2021/12/los3beyazbg2-300x132-2.png"
              alt="Losmedia logo"
              width={120}
              loading="lazy"
            />
          </a>
        </footer>
      </main>
    </>
  );
}

